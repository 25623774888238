import React, { lazy, useCallback, useContext, useEffect, useState } from "react";
import { VIEW_WORK_TICKET_COL } from "../../constants/ViewWorkTicketConstants";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { SLButton, Spacer } from "../../components/core/utility";
import edit from "../../assets/icons/edit.png";
import { Loader } from "@vds/loaders";
import { Notification } from "@vds/notifications";
import { Body } from "@vds/typography/dist/cjs/components/Body";
import { generate } from "shortid";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { C4SContext } from "../../context/c4sContext";
import { getInstallType, handleAddPage } from "./utils";
import { groupBy, orderBy, sortBy } from "lodash";
import InProgressImg from "../../assets/icons/InProgress.svg";
import ErrorImg from "../../assets/icons/Error.svg";
import ReleasedImg from "../../assets/icons/Released.svg";
import CancelImg from "../../assets/icons/Cancelled.svg";
import ReturnSubmittedImg from "../../assets/icons/ReturnSubmitted.svg";
import { workTicketList } from "../../services/C4S Decommission - Replacement/workTicketList";
import moment from "moment";
import ThreeDotsTemplate from "./ThreeDotsTemplate";
import { deleteWorkTicket } from "../../services/C4S Decommission - Replacement/deleteWorkTicket";
import { WorkViewItemTableMetadata } from "./WorkViewItemTable";
import searchIcon from "../../assets/icons/search_blk.png";
import styled from "styled-components";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import useOutsideClick from "../../customHook/use-outside-click";
import { Tooltip } from "primereact/tooltip";

const StyledIconContainer = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;
import { DetailedWorkTicketViewMetadata } from "./DetailedWorkTicketView";

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid black;
  width: 220px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, max-content);
  gap: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;

  @media screen and (min-width: 826px) and (max-width: 920px) {
    grid-template-columns: repeat(5, max-content);
  }
  @media (max-width: 826px) {
    grid-template-columns: repeat(4, max-content);
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const StyledNotification = styled(Notification)`
  & div {
    background: #0077b4 !important;
  }
`;

const WORK_TICKET_STATUS_LIST = [
  {
    label: "Released",
    scrImg: ReleasedImg,
    isCSP: true,
  },
  {
    label: "Error",
    scrImg: ErrorImg,
    isCSP: true,
  },
  {
    label: "Return Submitted",
    scrImg: ReturnSubmittedImg,
    isCSP: false,
  },
  {
    label: "Cancelled",
    scrImg: CancelImg,
    isCSP: true,
  },
];

export default function VehicleListTable(props) {
  const { cspDetails, notification, recordsUploaded, isSourceCSP } = useContext(C4SContext);
  const [isLoading, setIsLoading] = useState(false);
  const [workTicketListTable, setWorkTicketListTable] = useState([]);
  const [workTicketListColumns, setWorkTicketListColumns] = useState(
    isSourceCSP.isSourceCSP ? VIEW_WORK_TICKET_COL.toSpliced(1, 1) : VIEW_WORK_TICKET_COL
  );
  let caseData = null;
  sessionStorage && sessionStorage.getItem("CSPDetails") ? JSON.parse(sessionStorage.getItem("CSPDetails")) : null;
  caseData =
    sessionStorage && sessionStorage.getItem("CSPDetails")
      ? JSON.parse(sessionStorage.getItem("CSPDetails")).CASEDetails?.filter((caseDetail) => caseDetail.DefaultCase === "1")[0] ?? ""
      : null;
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    setTimeout(() => {
      getWorkTicketUpdateError();
    }, 500);
    getWorkTicketList();
  }, []);
  const getWorkTicketUpdateError = () => {
    let workticketUpdateError = sessionStorage.getItem("workticketUpdateError");
    if (workticketUpdateError) {
      notification.setNotification(
        <Notification
          key={generate()}
          type={"error"}
          title={`WorkTicket created but failed to update status on ${workticketUpdateError}`}
          fullBleed={true}
          ref={clickRef}
        />
      );
      sessionStorage.setItem("workticketUpdateError", "");
    }
  };
  let limitWT = sessionStorage.getItem("limitWTCreation") == "true" ? true : false;
  const renderImage = (data, rowData) => {
    let workTicketStatus = rowData?.status?.toLowerCase();
    let statusIcon;
    switch (workTicketStatus) {
      case "error":
        statusIcon = ErrorImg;
        break;
      case "released":
        statusIcon = ReleasedImg;
        break;
      case "cancelled":
        statusIcon = CancelImg;
        break;
      case "return submitted":
        statusIcon = ReturnSubmittedImg;
        break;
      default:
        statusIcon = InProgressImg;
    }
    return rowData.salesOrg === data ? (
      renderToolTip(data)
    ) : (
      <div css={{ display: "flex" }}>
        <img src={statusIcon} alt="Status-Icon" height="16px" width="16px" css={{ marginRight: "8px" }} />
        <Body primitive="span">{rowData?.ticketId}</Body>
      </div>
    );
  };

  const renderToolTip = (data) => {
    return (
      <>
        <Tooltip target=".sales-org" mouseTrack mouseTrackTop={10} />
        <span className="sales-org" data-pr-tooltip={data} data-pr-position="top">
          {data?.slice(0, 4)}
        </span>
      </>
    );
  };

  const getWorkTicketList = async () => {
    let cspAccountDetails = null;
    cspAccountDetails = sessionStorage && sessionStorage.getItem("CSPDetails") ? JSON.parse(sessionStorage.getItem("CSPDetails")) : null;
    if (cspAccountDetails === null) return false;
    updateNewrelicStats();
    setIsLoading(true);
    let payLoad = {
      accountId: props?.AccountID,
      cspId:
        isSourceCSP.isSourceCSP && cspAccountDetails?.CSP?.length
          ? cspAccountDetails.CSP.filter((csp) => csp.DefaultCSP === "1")[0].CSPID || null
          : null,
      caseId:
        !isSourceCSP.isSourceCSP && cspAccountDetails?.CASEDetails?.length
          ? cspAccountDetails.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseSFDCID || null
          : null,
    };
    let response = await workTicketList(payLoad);
    if (response.status === 200) {
      let responseWithCityState = response.data.data.map((data) => {
        data.installType = getInstallType(data.installType);
        data.cityState = `${data.city}, ${data.stateCode}`;
        return data;
      });
      let sortedResponse = orderBy(
        responseWithCityState,
        [
          function (o) {
            return new Date(o.modifiedAt);
          },
          function (o) {
            return new Date(o.createdAt);
          },
          "installType",
          "cityState",
        ],
        ["desc", "desc"]
      );
      if (!isSourceCSP.isSourceCSP) {
        let currentCase = groupBy(responseWithCityState, { caseNumber: caseData.CaseNumber }).true ?? [];
        let otherCase = groupBy(responseWithCityState, { caseNumber: caseData.CaseNumber }).false ?? [];
        currentCase = orderBy(
          currentCase,
          function (o) {
            return new Date(o.modifiedAt);
          },
          "desc"
        );
        otherCase = orderBy(
          otherCase,
          function (o) {
            return new Date(o.modifiedAt);
          },
          "desc"
        );
        sortedResponse = [...currentCase, ...otherCase];
      }
      sortedResponse = sortedResponse.map((data) => {
        return {
          caseNumber: data.caseNumber,
          ticketId: data.workticketId,
          migratedRecord: data.workticketId?.length && data.workticketId[0] === "W" ? false : true,
          workOrderOwner: data.workOrderOwner,
          installType: data.installType,
          cityState: `${data.city}, ${data.stateCode}`,
          status: data.wtStatus,
          requestDate: data.requestDate,
          erpTicketType: data.erpTicketType,
          addressOne: data.addressOne,
          addressTwo: data.addressTwo,
          city: data.city,
          stateCode: data.stateCode,
          country: data.country,
          zipCode: data.zipCode,
          workTicket: data.workticketId,
          contactName: data.contactName || "",
          phone: data.phoneNumber || "",
          email: data.emailAddress || "",
          division: data?.division || null,
          distributionChannel: data?.distChannel || null,
          salesOrg: data.salesOrg,
          // pgiDate: data.erpData[0].shipmentDate,
          contractAccount: data.contractAccount,
          assignedTo: data.assignTo,
          crmReferenceId: isSourceCSP.isSourceCSP
            ? cspAccountDetails?.CSP?.length
              ? cspAccountDetails.CSP[0].CRM_Ref_ID
              : null
            : cspAccountDetails?.CASEDetails?.length
            ? cspAccountDetails.CASEDetails[0].CaseNumber
            : null,
          currencyCode: data.currencyCode,
          erpPartnerNumber: cspAccountDetails?.ERP_Partner_Number ?? null,
          erpPrimaryContactNumber: cspAccountDetails?.ERP_Primary_Contact_NUmber ?? null,
          accountName: cspAccountDetails?.AccountName ?? null,
          cspCustomerPONumber: cspAccountDetails?.CSP_CUstomer_PO_Number ?? null,
          subStatus: data.subStatus,
          erpStatus: data.erpStatus,
          wareHouseNotes: data.wareHouseNotes,
          esnNotes: data.esnNotes,
          erpMigrationData: {
            x1ErpSalesRepId: data.x1ErpSalesRepId,
            xcontactMobile: data.xcontactMobile,
            xc4sObjectId: data.xc4sObjectId,
            xschedulingNotes: data.xschedulingNotes,
            xvendorMessage: data.xvendorMessage,
            xnotesToTechnician: data.xnotesToTechnician,
            xfieldNotes: data.xfieldNotes,
            xrequestStartDateTime: data.xrequestStartDateTime,
            xrequestEndDateTime: data.xrequestEndDateTime,
            xvendorId: data.xvendorId,
          },
          ownerName: data.assignedTo,
          assignedToEid: data.assignedToEid,
          assignedToEmail: data.assignedToEmail,
          createdBy: data.createdBy,
          createdByEid: data.createdByEid,
          createdByEmail: data.createdByEmail,
          createdOn: data.createdAt,
          modifiedAt: data?.modifiedAt ? moment(data.modifiedAt).format("MM/DD/YYYY") : null,
          modifiedBy: data.modifiedBy,
          modifiedByEid: data.modifiedByEid,
          modifiedByEmail: data.modifiedByEmail,
        };
      });
      setWorkTicketListTable(sortedResponse);
    } else {
      notify("We are currently experiencing issues, please try again", "error");
    }
    setIsLoading(false);
  };

  const navigate = (data) => {
    notification.setNotification(null);
    DetailedWorkTicketViewMetadata.name = data.ticketId;
    handleAddPage(DetailedWorkTicketViewMetadata.id, { data: data, AccountID: props?.AccountID, ID: props?.ID, userId: props?.userId });
  };

  // const onRowEditTemplate = (data) => {
  //   return (
  //     <img style={{ width: "15px", cursor: "pointer" }} alt="more-icon" src={edit} aria-controls="overlay_panel" onClick={() => navigate(data)} />
  //   );
  // };

  const notify = (content, type) => {
    notification.setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const callDeleteTicket = useCallback(async (ticketID) => {
    setIsLoading(true);
    const payload = { firstName: cspDetails.cspDetails.firstName, lastName: cspDetails.cspDetails.lastName, ticketID: ticketID };
    let response = await deleteWorkTicket(payload);
    if (response.status === 200 && response?.data?.statusCode === "200") {
      notify("Ticket has been deleted successfully!", "success");
      getWorkTicketList();
    } else {
      notify("We are currently experiencing issues, please try again", "error");
    }
    setIsLoading(false);
  }, []);

  const threeDotsTemplate = (rowData) => {
    return (
      <ThreeDotsTemplate
        ticketDetails={rowData}
        callDeleteTicket={callDeleteTicket}
        notify={notify}
        getWorkTicketList={getWorkTicketList}
        id={props?.ID}
        userId={props?.userId}
      />
    );
  };

  const onGlobalSearchChange = (e) => {
    setGlobalSearchValue(e.target.value);
  };

  const supportCaseBanner = !isSourceCSP.isSourceCSP && (
    <StyledNotification
      key={generate()}
      type="info"
      title={
        <>
          Your Case:<> {caseData?.CaseNumber}</>
        </>
      }
      // fullBleed={true}
    />
  );
  const caseIdentifier = (caseNumber) => (caseData ? caseNumber === caseData.CaseNumber : false);

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <div css={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInputWrapper>
          <SearchInput
            role="search"
            aria-label={globalSearchValue}
            value={globalSearchValue}
            type="text"
            onChange={onGlobalSearchChange}
            placeholder="Search"
          ></SearchInput>
          <img aria-label="search" role="img" src={searchIcon} height="20px" width="20px"></img>
        </SearchInputWrapper>
      </div>
      <Spacer bottom="0px"></Spacer>
      <Body tabIndex={0} css={{ fontFamily: "Verizon-NHG-eDS", fontSize: "1rem" }} size="large">
        Release tickets or schedule appointments.
      </Body>
      <Spacer bottom="1rem" />
      {workTicketListTable && workTicketListTable.length > 0 && (
        <div style={{ display: "flex" }}>
          <legend style={{ "font-size": "1rem", "font-weight": "600", "line-height": "1.25rem", "font-family": "Verizon-NHG-eDS" }}>Status: </legend>
          <Wrapper>
            {isSourceCSP.isSourceCSP
              ? WORK_TICKET_STATUS_LIST.filter((item) => item.isCSP).map((item) => {
                  return (
                    <StatusWrapper key={item.label + "_status"}>
                      <img src={item.scrImg} alt="Status-Icon" css={{ marginRight: "4px" }} />
                      <Body primitive="span">{`- ${item.label}`}</Body>
                    </StatusWrapper>
                  );
                })
              : WORK_TICKET_STATUS_LIST.map((item) => {
                  return (
                    <StatusWrapper key={item.label + "_status"}>
                      <img src={item.scrImg} alt="Status-Icon" css={{ marginRight: "4px" }} />
                      <Body primitive="span">{`- ${item.label}`}</Body>
                    </StatusWrapper>
                  );
                })}
          </Wrapper>
        </div>
      )}
      <GridComponent
        isSelectionEnabled={false}
        isExportEnabled={false}
        noDataMessage="No data available to display"
        rows={workTicketListTable}
        columns={workTicketListColumns}
        hideGlobalFilter={true}
        // onRowEditTemplate={onRowEditTemplate}
        renderDataWithImage={renderImage}
        caseIdentifier={caseIdentifier}
        paginator={true}
        threeDotsTemplate={!limitWT && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY) && threeDotsTemplate}
        hyperLinkClicked={navigate}
        selectionAutoFocus={false}
        headerBanner={supportCaseBanner}
        searchKeyboardValue={globalSearchValue}
      />
    </>
  );
}

export const ViewWorkTicketMetadata = {
  name: "Work Ticket",
  id: "View_work_ticket",
  component: lazy(() => import("./ViewWorkTicket")),
  route: "/view_work_ticket",
};
